<template>
  <div>
    <section>
      <div class="max-w-4xl mx-auto mt-6">
        <div class="text-center mb-12 text-2xl text-body-1 font-semibold">
          Právní služby
        </div>
        <h2
          v-if="this.currentProperty.propertyOwnership == 1"
          class="mx-8 font-bold text-2xl text-body-4 leading-12 mb-4 mt-8"
        >
          Smlouvy
        </h2>
        <!-- REZERVAČNÍ SMLOUVA -->
        <div
          v-if="this.currentProperty.propertyOwnership == 1"
          class="mx-8 bg-body-1 bg-opacity-5 p-6 rounded-md"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="">
              <h2 class="text-17 text-body-4 font-bold">Rezervační smlouva</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p
                  class="text-body-1 text-xl"
                  v-if="reservationContractComparator"
                >
                  ✔
                </p>
                <p v-if="!reservationContractComparator">
                  {{ reservationContract.price }} Kč
                </p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Rezervační smlouva chrání vás, jakožto prodejce nemovitosti, i
              zájemce o vaši nemovitost po dobu přípravy potřebných dokumentů a
              dalších kroků. Smlouva stanovuje datum, do kterého musí zájemce
              složit rezervační poplatek a dokdy musí být podepsána kupní
              smlouva. Stejně tak je zde definováno, co se stane, když některá
              strana smlouvu poruší.
            </p>
            <p class="pt-4 text-17">
              Vyhnete se tak situaci, kdy několik měsíců komunikujete s
              potenciálním zájemcem, který obchod nakonec z nějakého důvodu
              odřekne. Objednáním této služby získáte profesionální rezervační
              smlouvu, ověřenou důvěryhodnou advokátní kanceláří.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!reservationContractComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="reservationContract.stripePriceId"
                  />
                </div>
                <div
                  v-if="reservationContractComparator"
                  class="hover:opacity-50 duration-300"
                >
                  <button
                    @click="reservationContractForm = !reservationContractForm"
                    class="px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                  >
                    <img
                      src="/images/upload 1.png"
                      class="inline-flex mr-4"
                    />Vyplnit rezervační smlouvu
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>
        <!-- KUPNÍ SMLOUVA -->
        <div
          v-if="this.currentProperty.propertyOwnership == 1"
          class="mx-8 bg-body-1 bg-opacity-5 p-6 rounded-md mt-12"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div>
              <h2 class="text-17 text-body-4 font-bold">Kupní smlouva</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="salesContractComparator">
                  ✔
                </p>
                <p v-if="!salesContractComparator">
                  {{ salesContract.price }} Kč
                </p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Kupní smlouva popisuje předmět prodeje (vaši nemovitost) a
              definuje, jak bude kupec za nemovitost platit (splátky,
              jednorázová platba). Popis prodávané nemovitosti musí odpovídat
              katastru nemovitostí, proto je dobré mít po ruce list vlastnictví
              a nabývací dokumenty.
            </p>
            <p class="text-17 text-body-4 mt-4">
              Aplikace MakléřeNechci pošle všechny dostupné informace o
              prodávané nemovitosti ověřené advokátní kanceláři, kde právníci
              kupní smlouvu sepíší a následně vám ji pošlou na e-mail. V
              případě, že právní kancelář nebude mít dostatek informací vás bude
              kontaktovat.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!salesContractComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="salesContract.stripePriceId"
                  />
                </div>
                <div
                  v-if="salesContractComparator"
                  class="hover:opacity-50 duration-300"
                >
                  <button
                    @click="purchaseContract = !purchaseContract"
                    class="px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                  >
                    <img
                      src="/images/upload 1.png"
                      class="inline-flex mr-4"
                    />Vyplnit Kupní smlouvu
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>
        <!-- ÚSCHOVA PENĚZ -->
        <!-- <div
          v-if="this.currentProperty.propertyOwnership == 1"
          class="mx-8 bg-body-1 bg-opacity-5 p-6 rounded-md mt-12"
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="">
              <h2 class="text-17 text-body-4 font-bold">Úschova peněz</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="custodyOfMoneyComparator">
                  ✔
                </p>
                <p v-if="!custodyOfMoneyComparator">
                  {{ moneySafekeeping.price }} Kč +DPH
                </p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Při převodu resp. prodeji nemovitosti, musí být nemovitost
              přepsána v katastru nemovitostí. Je sice pravda, že nemovitost je
              de-facto prodaná podpisem smlouvy. Ale de-jure je nemovitost
              převedena až platným přepsáním v katastru nemovitostí. Protože by
              mohlo dojít v této době k „různým“ událostem a když nic jiného,
              tak byste mohli mít „dost pocuchané nervy“. Tedy smlouva o úschově
              peněz předně popisuje, co se bude dít krok za krokem. Tedy nejprve
              se podepíše smlouva, následně se peníze převedou na účet úschovy,
              až přijdou všechny peníze na účet úschovy, pošlou se dokumenty
              /kupní_smlouva a návrh na vklad/ na katastr nemovitostí, až
              katastr nemovitostí pošle vyrozumění, že nemovitost je „přepsána“,
              úschova uvolní peníze a pošle je podle smlouvy. Tím je převod
              nemovitosti ukončen. Úschovu peněz dělají banky, notáři a advokáti
              zapsáni v advokátní komoře.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!custodyOfMoneyComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="moneySafekeeping.stripePriceId"
                  />
                </div>
                <div v-if="custodyOfMoneyComparator"></div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div> -->

        <h2 class="mx-8 font-bold text-2xl text-body-4 leading-12 mb-4 mt-8">
          Služby
        </h2>
        <!-- KONZULTACE S ADVOKÁTEM -->
        <div class="mx-8 bg-body-1 bg-opacity-5 p-6 rounded-md">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="">
              <h2 class="text-17 text-body-4 font-bold">
                Konzultace s advokátem
              </h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="consultationComparator">
                  ✔
                </p>
                <p v-if="!consultationComparator">
                  {{ lawyerConsultation.price }} Kč / Hod.
                </p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Kdykoli během procesu prodeje nemovitosti můžete využít konzultace
              s právníkem. Schůzku si můžete sjednat on-line.
            </p>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="!consultationComparator">
                  <ButtonSpinnerStripe
                    :stripe-price-id="lawyerConsultation.stripePriceId"
                  />
                </div>
                <div v-if="consultationComparator">
                  <button
                    v-if="consultationDBTerm === null"
                    class="font-semibold px-4 mr-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                    @click="$router.push('/vyber-terminu-konzultace')"
                  >
                    Vybrat termín
                  </button>
                  <button
                    v-if="consultationDBTerm !== null"
                    class="modal-open w-48 mr-8 px-2 h-12 text-body-1 font-bold rounded-md focus:outline-none cursor-auto"
                  >
                    Termín: {{ consultationDBTerm.Date | moment("DD.MM.YYYY") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>
        <!-- Návrh na vklad nemovitosti -->
        <h2
          v-if="this.currentProperty.propertyOwnership == 1"
          class="mx-8 font-bold text-2xl text-body-4 leading-12 mb-4 mt-8"
        >
          Dokumenty
        </h2>
        <div
          v-if="this.currentProperty.propertyOwnership == 1"
          :class="
            'mx-8 bg-body-1 bg-opacity-5 p-6  rounded-md border-blue-700 ' +
            (propertyDepositOrdered ? 'border-2 border-blue-700' : '')
          "
        >
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class>
              <h2 class="text-17 text-body-4 font-bold">
                Návrh na vklad nemovitosti
              </h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="propertyDepositComparator">
                  ✔
                </p>
                <p v-if="!propertyDepositComparator">
                  {{ proposalDeposit.price }} Kč
                </p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Návrh na vklad nemovitosti je tiskopis předepsaný katastrálním
              úřadem a slouží k přepsání majitele nemovitosti při jejím prodeji.
              Tento dokument pro vás v rámci aplikace MakléřeNechci připraví
              ověřená advokátní kancelář a zašle vám ho na e-mail. V případě, že
              právní kancelář nebude mít dostatek informací vás bude kontaktovat
              e-mailem.
            </p>
            <p class="text-17 text-body-4 pt-4">
              V každém případě budete tento formulář potřebovat při převodu
              nemovitosti na katastrálním úřadu, tedy po podpisu kupní smlouvy.
            </p>
          </div>
          <div class="mt-4">
            <div
              class="text-red-600 bg-red-50 rounded-md p-4 border-2 border-red-600"
              v-if="!lvPayed"
            >
              <div class="font-bold">Chybí list vlastnictví</div>
              <div>
                Pro vygenerování dat z listu vlastnictví je potřeba jej zakoupit
                a vygenerovat v sekci
                <button
                  class="hover:font-bold"
                  @click="$router.push('strategie')"
                >
                  <div class="font-bold">Strategie a dokumentace</div></button
                >.
              </div>
            </div>
          </div>
          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div class="flex">
                  <div v-if="!propertyDepositComparator && lvPayed">
                    <ButtonSpinnerStripe
                      :stripe-price-id="proposalDeposit.stripePriceId"
                    />
                  </div>
                  <!-- <button
                    class="px-4 bg-body-1 hover:opacity-80 duration-300 h-12 text-white rounded-md focus:outline-none mr-2"
                  >
                    Stáhnout tiskopis
                  </button> -->
                </div>
                <div class="hover:opacity-50 duration-300">
                  <button
                    v-if="propertyDepositComparator"
                    @click="cadastralModalOpen = !cadastralModalOpen"
                    class="px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0"
                  >
                    <img
                      src="/images/upload 1.png"
                      class="inline-flex mr-4"
                    />Vyplnit tiskopis
                  </button>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
          </div>
        </div>
        <!--^ Návrh na vklad nemovitosti ^-->
        <div class="grid grid-cols-1 md:grid-cols-2 mt-12 mx-8 mb-24">
          <div class="hover:opacity-50 duration-300">
            <button
              class="px-8 text-body-3 border border-body-3 font-bold text-15 focus:outline-none rounded-md h-14"
              @click="$router.go(-1)"
            >
              Krok zpět
            </button>
          </div>
          <div
            class="text-left md:text-right hover:opacity-80 mt-8 md:mt-0 duration-300"
          >
            <button
              class="px-8 font-bold text-15 bg-body-3 text-white focus:outline-none rounded-md h-14"
              @click="save()"
            >
              Pokračovat na dashboard
            </button>
          </div>
        </div>
      </div>
    </section>
    <CadastralForm
      v-if="cadastralModalOpen"
      :cadastralModalOpen="cadastralModalOpen"
      :vyslednaDataLV="vyslednaDataLV"
      v-on:close="closeForm($event)"
    />
    <ReservationContract
      v-if="reservationContractForm"
      :vyslednaDataLV="vyslednaDataLV"
      v-on:closeReservationContract="closeReservationContract($event)"
    />
    <PurchaseContract
      v-if="purchaseContract"
      :purchaseContract="purchaseContract"
      v-on:closePurchaseContract="closePurchaseContract($event)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
//import { getMyTerm } from "@/graphql/queries/userTermsQueries.js";
import {
  getCadastralData,
  getUserTerms,
  getStripe,
} from "../../services/rest/services.js";
import ButtonSpinnerStripe from "@/components/buttons/ButtonSpinnerStripe";
import CadastralForm from "@/components/cadastral/CadastralForm";
import ReservationContract from "@/components/reservationContract/ReservationContract";
import PurchaseContract from "@/components/purchaseContract/PurchaseContract";
import axios from "axios";
import { IS_BETA, IS_LOCALHOST } from '../../globalConstants.js';

export default {
  components: {
    ButtonSpinnerStripe,
    CadastralForm,
    PurchaseContract,
    ReservationContract,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },

  async mounted() {
    //if (this.currentProperty.propertyOwnership !== 1) {
    await this.fetchLawyerConsultationTerm();
    const cuzkData = await getCadastralData(this.currentProperty.id);
    if (cuzkData.dataInDatabase) {
      if (this.currentProperty.propertyOwnership == 1) {
        this.fillCuzkData(cuzkData.data.data);
        this.lvPayed = true;
      }
    }
    if (this.$router.history.current.params) {
      if (this.$router.history.current.params.direct == true) {
        window.scrollTo(0, 1600);
      } else;
    }
    // Stripe getData by currentPropertyId MODULE
    const receipts = await getStripe(this.currentProperty.id);
    if (receipts) {
      let receiptDescriptionArray = [];
      receipts.forEach((receipt) =>
        receiptDescriptionArray.push(receipt.description)
      );
      this.paidServiceHandler(receiptDescriptionArray);
      this.allDataFetched = true;
    }
    // ^^^
  },

  data() {
    return {
      lvPayed: false,
      cadastralModalOpen: false,
      purchaseContract: false,
      reservationContractForm: false,
      proposalDeposit: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1N91MRI4HEbZGFYD8IQ6shCk"
          : "price_1LhUPxI4HEbZGFYDEg7aOffe",
        price: 2000,
        selectedStrategy: 2,
        files: [],
      },
      reservationContract: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1Iqe3TI4HEbZGFYD2v9FFYZ7"
          : "price_1LhUV8I4HEbZGFYD4BmgsJpg",
        price: 3000,
      },
      salesContract: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1Iqe8pI4HEbZGFYDBBiqVRNb"
          : "price_1LhUUKI4HEbZGFYDxPU4va2h",
        price: 3000,
      },
      moneySafekeeping: {
        stripePriceId: "price_1KOesRI4HEbZGFYDnpKEkLeX",
        price: 7260,
      },
      lawyerConsultation: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1KOewjI4HEbZGFYDiDUOCWB0"
          : "price_1LhUWVI4HEbZGFYDL11dOZUg",
        price: 600,
      },

      consultationTerms: [],
      consultationTerm: [],
      reactiveStaging: "",

      //PaidServices
      reservationContractComparator: false,
      salesContractComparator: false,
      custodyOfMoneyComparator: false,
      consultationComparator: false,

      //allDAtaFetched ?
      allDataFetched: false,

      //userTerms from DB
      allUserTerms: [],
      consultationDBTerm: [],

      //propertyDeposit
      propertyDepositOrdered: false,
      propertyDepositComparator: false,

      dataListVlastnictvi: [],
      vyslednaDataLV: {},
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.reactiveTermsDisplayHandler();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    closeForm(e) {
      this.cadastralModalOpen = e;
    },
    closePurchaseContract(e) {
      this.purchaseContract = e;
    },
    closeReservationContract(e) {
      this.reservationContractForm = e;
    },
    handlePropertyDeposit(ordered, proposalPropertyDeposit) {
      this.propertyDepositOrdered = ordered;
      this.strategiesAndDocs.proposalPropertyDeposit = proposalPropertyDeposit;
    },
    orderPropertyDeposit() {
      this.handlePropertyDeposit(true, this.proposalPropertyDeposit);
    },
    removePropertyDeposit() {
      this.handlePropertyDeposit(false, null);
    },
    async userTermsHandler() {
      const userPaymentResults = await axios
        .get(
          `${process.env.VUE_APP_STRAPI_API_URL}/user-terms/${this.currentProperty.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        )
        .then((this.allDataFetched = true));
      this.allUserTerms = userPaymentResults.data;

      const allServiceTypes = async () => {
        this.consultationDBTerm =
          this.allUserTerms.find((i) => i.Type === "consultation") || null;
      };
      await allServiceTypes();
    },

    async paidServiceHandler(receiptDescriptionArray) {
      if (receiptDescriptionArray.includes("1x Rezervační smlouva")) {
        this.reservationContractComparator = true;
      } else this.reservationContractComparator = false;
      if (receiptDescriptionArray.includes("1x Prodejní smlouva")) {
        this.salesContractComparator = true;
      } else this.salesContractComparator = false;
      if (receiptDescriptionArray.includes("1x Úschova peněz")) {
        this.custodyOfMoneyComparator = true;
      } else this.custodyOfMoneyComparator = false;
      if (receiptDescriptionArray.includes("1x Konzultace s advokátem")) {
        this.consultationComparator = true;
      } else this.consultationComparator = false;
      if (receiptDescriptionArray.includes("1x Návrh na vklad nemovitosti")) {
        this.propertyDepositComparator = true;
      } else this.propertyDepositComparator = false;
      await this.userTermsHandler();
    },

    reactiveTermsDisplayHandler() {
      const reactiveType = this.$route.params.type;
      if (reactiveType === "consulting" && this.consultationTerms.length == 0) {
        return (this.consultationTerm = this.$route.params.savedTerm);
      } else return;
    },

    async fetchLawyerConsultationTerm() {
      try {
        this.type = "consultation";
        const result = await getUserTerms(this.currentProperty.id, this.type);
        this.consultationTerms = result.data.userTerms;
        if (!this.consultationTerms.length > 0) {
          this.consultationTerm = this.consultationTerms[
            this.consultationTerms.length - 1
          ].Date;
        } else return;
      } catch (error) {
        error;
      }
    },

    save() {
      try {
        this.$router.push("/nemovitost");
      } catch (error) {
        this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
      }
    },

    async fillCuzkData(lv) {
      let dCase = lv.documentCase;
      let lvData = lv.lvData;
      let lvAdditionals = lv.xmlToJsObject;

      if (lv.documentCase == 1) {
        this.vyslednaDataLV = {
          documentCase: dCase,
          lvAdditionals: {
            pozemky: [],
            subjekty: [],
          },
          lvIdentity: {
            CISLO_LV: lvData.CISLO_LV[0],
            KATASTR_UZEMI_KOD: lvData.KATASTR_UZEMI[0].kod[0],
            KATASTR_UZEMI_NAZEV: lvData.KATASTR_UZEMI[0].nazev[0],
            OBEC_KOD: lvData.OBEC[0].kod[0],
            OBEC_NAZEV: lvData.OBEC[0].nazev[0],
            OKRES_KOD: lvData.OKRES[0].nuts4[0],
            OKRES_NAZEV: lvData.OKRES[0].nazev[0],
            TEL_ID: lvData.TEL_ID[0],
          },
        };
        this.vyslednaDataLV.lvAdditionals.subjekty.push(
          ...lvAdditionals.VLASTNICI_JINI_OPRAVNENI
        );
      }

      if (lv.documentCase == 2) {
        this.vyslednaDataLV = {
          documentCase: dCase,
          lvAdditionals: {
            pozemky: [],
            subjekty: [],
          },
          lvIdentity: {
            CISLO_LV: lvData.CISLO_LV[0],
            KATASTR_UZEMI_KOD: lvData.KATASTR_UZEMI[0].kod[0],
            KATASTR_UZEMI_NAZEV: lvData.KATASTR_UZEMI[0].nazev[0],
            OBEC_KOD: lvData.OBEC[0].kod[0],
            OBEC_NAZEV: lvData.OBEC[0].nazev[0],
            OKRES_KOD: lvData.OKRES[0].nuts4[0],
            OKRES_NAZEV: lvData.OKRES[0].nazev[0],
            TEL_ID: lvData.TEL_ID[0],
          },
        };
        this.vyslednaDataLV.lvAdditionals.pozemky.push(
          ...lvAdditionals.POZEMKY
        );
        this.vyslednaDataLV.lvAdditionals.subjekty.push(
          ...lvAdditionals.VLASTNICI_JINI_OPRAVNENI
        );
      }

      if (lv.documentCase == 3) {
        this.vyslednaDataLV = {
          documentCase: dCase,
          lvIdentity: {
            CISLO_LV: lvData.CISLO_LV[0],
            KATASTR_UZEMI_KOD: lvData.KATASTR_UZEMI[0].kod[0],
            KATASTR_UZEMI_NAZEV: lvData.KATASTR_UZEMI[0].nazev[0],
            OBEC_KOD: lvData.OBEC[0].kod[0],
            OBEC_NAZEV: lvData.OBEC[0].nazev[0],
            OKRES_KOD: lvData.OKRES[0].nuts4[0],
            OKRES_NAZEV: lvData.OKRES[0].nazev[0],
            TEL_ID: lvData.TEL_ID[0],
          },
        };
      }
      return;
    },
  },
};
</script>
