<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      v-if="!successSend"
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        v-if="start"
        v-click-outside="onClickOutside"
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="mt-3 text-left sm:mt-0 sm:text-left p-0">
            <p class="text-center font-bold">Formulář rezervační smlouvy</p>
            <!-- Jméno prodejce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Jméno prodejce
              </label>
            </div>
            <input
              :style="
                reservationForm.seller_name === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.seller_name"
              @blur="saveData()"
            />
            <!-- Příjmení prodejce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Příjmení prodejce
              </label>
            </div>
            <input
              :style="
                reservationForm.seller_surname === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.seller_surname"
              @blur="saveData()"
            />
            <!-- Rodné číslo prodejce bez lomítka -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Rodné číslo prodejce bez lomítka
              </label>
            </div>
            <input
              :style="
                reservationForm.seller_pin === '' && error ? cssInvalid : ''
              "
              v-mask="'##########'"
              :class="inputCSS"
              v-model="reservationForm.seller_pin"
              @blur="saveData()"
            />
            <!-- Trvalý pobyt prodejce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Trvalý pobyt prodejce
              </label>
            </div>
            <input
              :style="
                reservationForm.seller_permanent_address === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="reservationForm.seller_permanent_address"
              @blur="saveData()"
            />
            <!-- KUPEC -->
            <!-- Jméno kupce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Jméno kupujícího
              </label>
            </div>
            <input
              :style="
                reservationForm.customer_name === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.customer_name"
              @blur="saveData()"
            />
            <!-- Příjmení kupce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Příjmení kupujícího
              </label>
            </div>
            <input
              :style="
                reservationForm.customer_surname === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="reservationForm.customer_surname"
              @blur="saveData()"
            />
            <!-- Rodné číslo kupce bez lomítka -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Rodné číslo kupujícího bez lomítka
              </label>
            </div>
            <input
              :style="
                reservationForm.customer_pin === '' && error ? cssInvalid : ''
              "
              v-mask="'##########'"
              :class="inputCSS"
              v-model="reservationForm.customer_pin"
              @blur="saveData()"
            />
            <!-- Trvalý pobyt kupce -->
            <div class="pt-4">
              <label class="text-xl text-body-4 font-bold">
                Adresa kupujícího
              </label>
            </div>
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> Ulice </label>
            </div>
            <input
              :style="
                reservationForm.customer_street === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="reservationForm.customer_street"
              @blur="saveData()"
            />
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo popisné
              </label>
            </div>
            <input
              :style="
                reservationForm.customer_cp === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.customer_cp"
              @blur="saveData()"
            />
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> Město </label>
            </div>
            <input
              :style="
                reservationForm.customer_city === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.customer_city"
              @blur="saveData()"
            />
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> PSČ </label>
            </div>
            <input
              :style="
                reservationForm.customer_psc === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.customer_psc"
              @blur="saveData()"
            />

            <div class="pt-8 font-bold text-lg">Prodávaná nemovitost</div>
            <div class="flex pt-2">
              <div>
                <Info />
              </div>
              <span
                style="line-height: 24px"
                class="text-body-1 text-md select-none pl-2"
                >Pole, které se nevyplnili automaticky, si prosím vyplňte.
                Všechny informace naleznete v zakoupeném listu vlastnictví.
              </span>
            </div>

            <p
              class="bg-red-200 rounded text-sm font-semibold px-3 py-1"
              v-if="!lvData"
            >
              Nemáte zakoupený list vlastnictví pro automatické doplnění údajů
            </p>
            <!-- Číslo bytové jednotky -->
            <div v-if="currentProperty.propertyType === 1" class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo bytové jednotky
              </label>
            </div>
            <input
              v-if="currentProperty.propertyType === 1"
              :class="inputCSS"
              v-model="reservationForm.cislo_jednotky"
              @blur="saveData()"
            />
            <!-- Číslo podlaží -->
            <div v-if="currentProperty.propertyType === 1" class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo podlaží
              </label>
            </div>
            <input
              v-if="currentProperty.propertyType === 1"
              v-mask="'######'"
              :class="inputCSS"
              v-model="reservationForm.cislo_podlazi"
              @blur="saveData()"
            />
            <!-- Číslo popisné -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo popisné
              </label>
            </div>
            <input
              :class="inputCSS"
              v-model="reservationForm.cislo_popisne"
              @blur="saveData()"
            />
            <!-- Číslo parcely -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo parcely
              </label>
            </div>
            <input
              :style="
                reservationForm.cislo_parcely === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.cislo_parcely"
              @blur="saveData()"
            />
            <!-- Velikostní podíl -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Velikostní podíl
              </label>
            </div>
            <div class="grid grid-cols-1 gap-4">
              <div>
                <input
                  :style="
                    reservationForm.velikostni_podil1 === '' && error
                      ? cssInvalid
                      : ''
                  "
                  type="text"
                  style="width: 160px"
                  :class="inputCSS"
                  v-model="reservationForm.velikostni_podil1"
                  @blur="saveData()"
                />
              </div>
            </div>
            <!-- Katastrální území -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Katastrální území
              </label>
            </div>
            <input
              :style="
                reservationForm.katastralni_uzemi === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="reservationForm.katastralni_uzemi"
              @blur="saveData()"
            />
            <!-- Obec -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> V obci </label>
            </div>
            <input
              :style="reservationForm.obec === '' && error ? cssInvalid : ''"
              :class="inputCSS"
              v-model="reservationForm.obec"
              @blur="saveData()"
            />
            <!-- Číslo listu vlastnictví -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo listu vlastnictví
              </label>
            </div>
            <input
              :style="
                reservationForm.list_vlastnictvi === '' && error
                  ? cssInvalid
                  : ''
              "
              v-mask="'#########'"
              :class="inputCSS"
              v-model="reservationForm.list_vlastnictvi"
              @blur="saveData()"
            />
            <!-- Katastrální pracoviště -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Katastrální pracoviště
              </label>
            </div>
            <input
              :style="
                reservationForm.katastralni_pracoviste === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="reservationForm.katastralni_pracoviste"
              @blur="saveData()"
            />
            <!-- Cena nemovitosti -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Cena nemovitosti
              </label>
            </div>
            <input
              :class="inputCSS"
              v-model="cenaNemovitosti"
              @blur="saveData()"
            />
            <!-- Část ceny k uhrazeni -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Část z ceny nemovitosti bude uhrazena (rezervační částka)
              </label>
            </div>
            <input
              :style="
                reservationForm.cena_nemovitosti_cast_bude_uhrazena === '' &&
                error
                  ? cssInvalid
                  : ''
              "
              v-mask="'#######'"
              :class="inputCSS"
              v-model="reservationForm.cena_nemovitosti_cast_bude_uhrazena"
              @blur="saveData()"
            />
            <!-- Číslo účtu -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo účtu prodejce pro uhrazení rezervační částky
              </label>
            </div>
            <input
              :style="
                reservationForm.cislo_uctu === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="reservationForm.cislo_uctu"
              @blur="saveData()"
            />
          </div>
        </div>
        <div
          v-if="error"
          class="text-center py-2 px-4 bg-red-200 font-semibold"
        >
          {{ errorMessage }}
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between"
        >
          <button @click="[userFormValidator()]" type="button" class="btn">
            Poslat na e-mail
          </button>
          <button
            @click="closeReservationContract()"
            type="button"
            class="btnC"
          >
            Zavřít okno
          </button>
        </div>
      </div>
    </div>
    <div v-if="successSend">
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-center sm:text-left pb-2">
                  <h3
                    class="text-xl leading-6 font-bold text-body-1 select-none py-8 text-center"
                    id="modal-title"
                  >
                    <p>Odesláno na {{ this.user.email }}</p>
                  </h3>
                  <div class="flex justify-center pb-4">
                    <ErrorSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getReservationData,
  getCadastralData,
  getComposition,
} from "../../services/rest/services";
import axios from "axios";
import { mapState } from "vuex";
import ErrorSvg from "../svg/Error.vue";
import Vue from "vue";
import Info from "../../components/svg/Info.vue";
+Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  props: {
    vyslednaDataLV: {
      type: Object,
    },
  },

  components: {
    ErrorSvg,
    Info,
  },

  data() {
    return {
      parcelId: "",
      cssInvalid:
        "border: 1px solid #fca5a5; background-color: rgba(252,165,165, 0.2);",
      successSend: false,
      reservationData: [],
      correct: false,
      lettersExpression: /[a-zA-Z]/g,
      error: false,
      errorMessage: "",
      postal: false,
      inputCSS:
        "w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",
      reservationForm: {
        seller_name: "",
        seller_surname: "",
        seller_pin: "",
        seller_permanent_address: "",
        customer_psc: "",
        customer_street: "",
        customer_cp: "",
        customer_city: "",
        customer_name: "",
        customer_surname: "",
        customer_pin: "",
        customer_permanent_address: "",
        cislo_jednotky: "",
        cislo_podlazi: "",
        cislo_popisne: "",
        cislo_parcely: "",
        katastralni_uzemi: "",
        obec: "",
        list_vlastnictvi: "",
        katastralni_pracoviste: "",
        cena_nemovitosti: "",
        cena_nemovitosti_cast_bude_uhrazena: "",
        cislo_uctu: "",
        velikostni_podil1: "",
        velikostni_podil2: "",
      },
      start: false,
      lvData: false,
      podil: "",
      podilNaDomu: "",
      cisloParcelyBezStavby: "",
      cadastralPlace: "",
      katastrUzemiFound: false,
    };
  },

  async mounted() {
    setTimeout(() => {
      this.start = true;
    }, 200);
    if (localStorage.getItem("reservationForm")) {
      this.reservationForm = JSON.parse(
        localStorage.getItem("reservationForm")
      );
    }

    this.reservationForm.cislo_jednotky = this.currentProperty.flatNumber;

    await getCadastralData(this.currentProperty.id).then((res) => {
      this.lvData = res.dataInDatabase || [];

      if (!res.dataInDatabase) {
        return;
      } else {
        if (this.cadastralPlace) {
          this.cadastralPlace = res.data.data.cadastralPlace;
        } else {
          this.cadastralPlace = "";
        }
        if (this.currentProperty.propertyType === 1) {
          this.podil = res.data.data.podil[0];
        }
        this.podilNaDomu = "";
        let stavebniPozemek = [];
        if (this.currentProperty.propertyType === 2) {
          if (res.data.data.xmlToJsObject) {
            // Parcel id by another way
            if (res.data.data.xmlToJsObject.POZEMKY) {
              res.data.data.xmlToJsObject.POZEMKY.forEach((pozemek) => {
                if (pozemek.DRUH_POZ[0].includes("zastavěná")) {
                  if (pozemek.PAR_IDENT[0].parcela[0]) {
                    if (pozemek.PAR_IDENT[0].parcela[0].par_cis[0]) {
                      if (pozemek.PAR_IDENT[0].parcela[0].podd_cis && pozemek.PAR_IDENT[0].parcela[0].podd_cis[0]) {
                        stavebniPozemek.push(
                          `${pozemek.PAR_IDENT[0].parcela[0].par_cis[0]}.${pozemek.PAR_IDENT[0].parcela[0].podd_cis[0]}`.trim()
                        );
                        this.reservationForm.cislo_parcely = stavebniPozemek.toString();
                      }
                    }
                  }
                } else return;
              });
            }
            if (res.data.data.xmlToJsObject.VLASTNICI_JINI_OPRAVNENI[0]) {
              if (
                res.data.data.xmlToJsObject.VLASTNICI_JINI_OPRAVNENI[0].PODIL
              ) {
                this.podilNaDomu =
                  res.data.data.xmlToJsObject.VLASTNICI_JINI_OPRAVNENI[0]
                    .PODIL || "";
              }
            }
          }
          // Conditions for problematic houses data
          if (res.data.data) {
            if (res.data.data.lvData) {
              // Katastr název území
              if (res.data.data.lvData.KATASTR_UZEMI) {
                if (res.data.data.lvData.KATASTR_UZEMI[0]) {
                  if (res.data.data.lvData.KATASTR_UZEMI[0].nazev) {
                    this.katastrUzemiFound = true;
                    this.reservationForm.katastralni_uzemi =
                      res.data.data.lvData.KATASTR_UZEMI[0].nazev[0];
                  }
                }
              }
            }
          }
        }
      }
    });

    if (this.currentProperty) {
      this.fullFillForm();
    }

    if (this.vyslednaDataLV) {
      const data = await getComposition(this.currentProperty.id);

      if (data.parcelId) this.parcelId = data.parcelId;
      this.fillFormByCadastralCase();
    }
  },

  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
    cenaNemovitosti() {
      return Intl.NumberFormat("cs-CS", {
        style: "currency",
        currency: "CZK",
      }).format(this.reservationForm.cena_nemovitosti);
    },
  },

  methods: {
    async userFormValidator() {
      this.reservationData = await getReservationData(this.currentProperty.id);
      this.error = false;
      if (this.vyslednaDataLV.documentCase == 3) {
        if (
          this.reservationForm.velikostni_podil1 === ""
        ) {
          this.error = true;
          return (this.errorMessage = "Podíly musí být vyplněny!");
        }
      }
      if (
        this.reservationForm.seller_name === "" ||
        this.reservationForm.seller_surname === "" ||
        this.reservationForm.seller_pin === "" ||
        this.reservationForm.seller_permanent_address === "" ||
        this.reservationForm.customer_name === "" ||
        this.reservationForm.customer_surname === "" ||
        this.reservationForm.customer_pin === "" ||
        this.reservationForm.katastralni_uzemi === "" ||
        this.reservationForm.obec === "" ||
        this.reservationForm.list_vlastnictvi === "" ||
        this.reservationForm.katastralni_pracoviste === "" ||
        this.reservationForm.cena_nemovitosti_cast_bude_uhrazena === "" ||
        this.reservationForm.cislo_uctu === ""
      ) {
        this.error = true;
        return (this.errorMessage = "Údaje nejsou kompletní!");
      } else if (
        !this.reservationForm.cislo_uctu.includes("/") ||
        this.lettersExpression.test(this.reservationForm.cislo_uctu)
      ) {
        this.error = true;
        return (this.errorMessage = "Špatné číslo účtu hrazené částky!");
      } else if (this.reservationForm.cena_nemovitosti === null) {
        this.error = true;
        return (this.errorMessage =
          "Cena nemovitosti není uvedená! Uveďte ji v sekci Strategie a dokumentace.");
      } else if (this.reservationData !== false) {
        this.error = true;
        return (this.errorMessage = "Rezervační smlouvu již máte vytvořenou!");
      } else {
        this.correct = true;
      }

      if (this.correct) {
        await this.reservationcontract();
      }
      //closeReservationContract()
    },
    async reservationcontract() {
      if (this.reservationForm.cislo_uctu.includes("/")) {
        this.reservationForm.cislo_uctu = this.reservationForm.cislo_uctu.replace(
          "/",
          "."
        );
      }
      if (this.reservationForm.cislo_jednotky.includes("/")) {
        this.reservationForm.cislo_jednotky = this.reservationForm.cislo_jednotky.replace(
          "/",
          "."
        );
      }
      if (this.reservationForm.velikostni_podil1.includes("/")) {
        this.reservationForm.velikostni_podil1 = this.reservationForm.velikostni_podil1.replace(
          "/",
          "."
        );
      }
      const params = JSON.stringify({
        case: this.vyslednaDataLV.documentCase,
        id: this.user.id,
        id_property: this.currentProperty.id,
        name: this.user.nameSurname.split(" ")[0],
        surname: this.user.nameSurname.split(" ")[1],
        email: this.user.username,
        ...this.reservationForm,
      });
      this.successSend = true;
      const data = await axios.get(
        `${process.env.VUE_APP_STRAPI_API_URL}/properties/reservationcontract/formfill/${params}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }
      );
      if (data.status === 200) {
        await axios
          .get(
            `${process.env.VUE_APP_STRAPI_API_URL}/properties/reservationcontract/getdocument/${this.currentProperty.id}`,
            {
              headers: {
                Authorization: `Bearer ${this.user.jwt}`,
              },
            }
          )
          .then(
            (this.successSend = true),
            setTimeout(() => {
              this.closeReservationContract();
            }, 3000)
          );

        return data;
      }
      return data;
    },
    closeReservationContract() {
      this.$emit("closeReservationContract", false);
    },
    saveData() {
      if (this.reservationForm.cislo_parcely.includes("/")) {
        this.reservationForm.cislo_parcely = this.reservationForm.cislo_parcely.replace(
          "/",
          "."
        );
      }
      window.localStorage.setItem(
        "reservationForm",
        JSON.stringify(this.reservationForm)
      );
    },
    addressHandler(e) {
      this.postal = e;
    },
    fullFillForm() {
      this.reservationForm.seller_name =
        this.user.nameSurname.split(" ")[0] || "";
      this.reservationForm.seller_surname =
        this.user.nameSurname.split(" ")[1] || "";
      this.reservationForm.cena_nemovitosti = this.currentProperty.propertyPrice;
      this.reservationForm.vymera_balkonu = this.currentProperty.usableAreaBalcony;
      this.reservationForm.cislo_popisne = this.currentProperty.address
        .split(" ")[1]
        .replace(/^\D+/g, "")
        .replace(/\s/g, "")
        .trim();

      // save into localStorage
      this.saveData();
    },
    fillFormByCadastralCase() {
      let dCase = this.vyslednaDataLV.documentCase;
      let lvIdentity = this.vyslednaDataLV.lvIdentity;
      let additionals = this.vyslednaDataLV.lvAdditionals;
    
      if (dCase && lvIdentity) {
        if (dCase == 1 && additionals) {
          this.setIdentity(lvIdentity);
          this.setFromOneSubject(additionals.subjekty[0], additionals.pozemky);
        }
        if (dCase == 2 && additionals) {
          this.setIdentity(lvIdentity);
          this.setFromTwoSubjects(additionals.subjekty, additionals.pozemky);
        }
        if (dCase == 3) {
          this.setIdentity(lvIdentity);
        }
      }
    },
    setIdentity(i) {
      if (!this.katastrUzemiFound) {
        this.reservationForm.katastralni_uzemi = this.cadastralPlace.split(
          ","
        )[0];
      }
      this.reservationForm.obec = i.OBEC_NAZEV;
      this.reservationForm.list_vlastnictvi = i.CISLO_LV;
      this.reservationForm.katastralni_pracoviste = this.cadastralPlace.split(
        ","
      )[1];
      if (this.currentProperty.propertyType === 1) {
        if (this.podil && this.podil.citatel && this.podil.jmenovatel && this.podil.citatel[0] && this.podil.jmenovatel[0]) {
          this.reservationForm.velikostni_podil1 = `${this.podil.citatel[0]}/${this.podil.jmenovatel[0]}`;
        }
      }
      this.reservationForm.velikostni_podil1 = "";
      if (this.currentProperty.propertyType === 2) {
        if (this.podilNaDomu) {
          if (this.podilNaDomu[0]) {
            this.reservationForm.velikostni_podil1 = `${this.podilNaDomu[0].citatel[0]}/${this.podilNaDomu[0].jmenovatel[0]}`;
          }
        }
      }
    },
    setFromOneSubject(me, lands) {
      const address = me.VLA_IDENT[0].oprav_subjekt[0].adresa[0];
      const subjectName = me.VLA_IDENT[0].oprav_subjekt[0];

      const rc = me.OPSUB_IDENT[0];
      if (subjectName.prijmeni && subjectName.jemno) {
        this.reservationForm.seller_surname = subjectName.prijmeni[0];
        this.reservationForm.seller_name = subjectName.jmeno[0];
      } else {
        this.reservationForm.seller_surname = this.user.nameSurname.split(
          " "
        )[1];
        this.reservationForm.seller_name = this.user.nameSurname.split(" ")[0];
      }

      if (rc.rc6 && rc.rc7) {
        this.reservationForm.seller_pin = `${rc.rc6[0]}${rc.rc7[0]}`;
      }
      if (address) {
        this.reservationForm.seller_permanent_address = `${address.nazev_ulice[0]} ${address.cislo_domovni[0]}, ${address.obec[0]}, ${address.psc[0]}`;
      }
      if (lands.length) {
        this.parcelHandler(lands);
      }
      return lands;
    },
    setFromTwoSubjects(subjects, lands) {
      //filled subject is filtered by user surname in database
      const address = subjects[0].VLA_IDENT[0].oprav_subjekt[0].adresa[0];
      const subjectName = subjects[0].VLA_IDENT[0].oprav_subjekt[0];
      const rc = subjects[0].OPSUB_IDENT[0];
      this.reservationForm.seller_surname = subjectName.prijmeni[0];
      this.reservationForm.seller_name = subjectName.jmeno[0];
      this.reservationForm.seller_pin = `${rc.rc6[0]}${rc.rc7[0]}`;
      this.reservationForm.seller_permanent_address = `${address.nazev_ulice[0]} ${address.cislo_domovni[0]}, ${address.obec[0]}, ${address.psc[0]}`;
      this.parcelHandler(lands);
    },
    parcelHandler(lands) {
      //each parcel number to array
      this.reservationForm.cislo_parcely = [];
      lands.forEach((land) => {
        this.reservationForm.cislo_parcely.push(
          land.PAR_IDENT[0].parcela[0].par_cis[0].trim()
        );
      });
      if (this.parcelId !== "") {
        this.reservationForm.cislo_parcely = this.parcelId;
      }
      //from array to string because of rest request
      if (this.currentProperty.propertyType === 1) {
        this.reservationForm.cislo_parcely = this.reservationForm.cislo_parcely.toString();
      }
      if (this.currentProperty.propertyType === 2) {
        lands.forEach((land) => {
          if (land.SOUCASTI.includes("a")) {
            if (land.STAVBA_NA_VICE_PARCELACH) {
              this.cisloParcelyBezStavby = land.STAVBA_NA_VICE_PARCELACH[0].parcela[0].par_cis[0].trim();
            }
          }
          if (land.SOUCASTI.includes("n")) {
            if (land.PAR_IDENT) {
              this.reservationForm.cislo_parcely = land.PAR_IDENT[0].parcela[0].par_cis[0].trim();
            }
          }
          if (
            this.reservationForm.cislo_parcely &&
            this.cisloParcelyBezStavby
          ) {
            this.reservationForm.cislo_parcely = `${this.reservationForm.cislo_parcely}, ${this.cisloParcelyBezStavby}`;
          }
        });
      }
    },
    onClickOutside() {
      if (this.start) {
        this.closeReservationContract();
      }
    },
  },
};
</script>
<style scoped>
.btn {
  background-color: #1b26e2 !important;
  color: white !important;
  border-radius: 8px !important;
  margin: 8px;
  height: 50px !important;
  width: 180px !important;
}

.btnC {
  background-color: #ffffff !important;
  color: #1b26e2 !important;
  border-radius: 8px !important;
  border: 2px solid #1b26e2;
  margin: 8px;
  height: 50px !important;
  width: 120px !important;
}

.btn:hover,
.btnC:hover {
  opacity: 0.7;
}
</style>
