<template>
  <div
    v-if="purchaseContract"
    class="fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      v-if="!successSend"
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        v-if="start"
        v-click-outside="onClickOutside"
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="mt-3 text-left sm:mt-0 sm:text-left p-0">
            <p class="text-center font-bold">Formulář kupní smlouvy</p>
            <div
              class="text-justify text-sm bg-red-100 mt-4 p-2"
              v-if="!reservationData"
            >
              Nemáte vytvořenou žádnou rezervaci. Doporučujeme prvně vytvořit
              rezervační smlouvu, z které se předvyplní většina údajů do
              formuláře kupní smlouvy.
            </div>
            <!-- Jméno prodejce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Jméno prodejce
              </label>
            </div>
            <input
              :style="
                purchaseForm.seller_name === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.seller_name"
              @blur="saveData()"
            />
            <!-- Příjmení prodejce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Příjmení prodejce
              </label>
            </div>
            <input
              :style="
                purchaseForm.seller_surname === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.seller_surname"
              @blur="saveData()"
            />
            <!-- Rodné číslo prodejce bez lomítka -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Rodné číslo prodejce bez lomítka
              </label>
            </div>
            <input
              :style="purchaseForm.seller_pin === '' && error ? cssInvalid : ''"
              v-mask="'##########'"
              :class="inputCSS"
              v-model="purchaseForm.seller_pin"
              @blur="saveData()"
            />
            <!-- Trvalý pobyt prodejce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Trvalý pobyt prodejce
              </label>
            </div>
            <input
              :style="
                purchaseForm.seller_permanent_address === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.seller_permanent_address"
              @blur="saveData()"
            />
            <!-- KUPEC -->
            <!-- Jméno kupce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> Jméno kupce </label>
            </div>
            <input
              :style="
                purchaseForm.customer_name === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.customer_name"
              @blur="saveData()"
            />
            <!-- Příjmení kupce -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Příjmení kupce
              </label>
            </div>
            <input
              :style="
                purchaseForm.customer_surname === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.customer_surname"
              @blur="saveData()"
            />
            <!-- Rodné číslo kupce bez lomítka -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Rodné číslo kupce bez lomítka
              </label>
            </div>
            <input
              :style="
                purchaseForm.customer_pin === '' && error ? cssInvalid : ''
              "
              v-mask="'##########'"
              :class="inputCSS"
              v-model="purchaseForm.customer_pin"
              @blur="saveData()"
            />
            <div class="pt-4">
              <label class="text-xl text-body-4 font-bold">
                Adresa kupce
              </label>
            </div>
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> Ulice </label>
            </div>
            <input
              :style="
                purchaseForm.customer_street === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.customer_street"
              @blur="saveData()"
            />
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo popisné
              </label>
            </div>
            <input
              :style="
                purchaseForm.customer_cp === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.customer_cp"
              @blur="saveData()"
            />
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> Město </label>
            </div>
            <input
              :style="
                purchaseForm.customer_city === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.customer_city"
              @blur="saveData()"
            />
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold"> PSČ </label>
            </div>
            <input
              :style="
                purchaseForm.customer_psc === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.customer_psc"
              @blur="saveData()"
            />
            <!-- Číslo bytové jednotky -->
            <div v-if="currentProperty.propertyType === 1" class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo bytové jednotky
              </label>
            </div>
            <input
              v-if="currentProperty.propertyType === 1"
              :class="inputCSS"
              v-model="purchaseForm.cislo_jednotky"
              @blur="saveData()"
            />
            <!-- Vymezeno v pozemku -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Vymezeno v m²
              </label>
            </div>
            <input
              :style="
                purchaseForm.vymezeno_v_pozemku === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.vymezeno_v_pozemku"
              @blur="saveData()"
            />
            <!-- Číslo popisné -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo popisné
              </label>
            </div>
            <input
              :style="
                purchaseForm.cislo_popisne === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.cislo_popisne"
              @blur="saveData()"
            />
            <!-- Číslo parcely -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo parcely
              </label>
            </div>
            <input
              :style="
                purchaseForm.cislo_parcely === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.cislo_parcely"
              @blur="saveData()"
            />
            <!-- Katastrální území -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Katastrální území
              </label>
            </div>
            <input
              :style="
                purchaseForm.katastralni_uzemi === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.katastralni_uzemi"
              @blur="saveData()"
            />
            <!-- Velikostní podíl -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Velikostní podíl
              </label>
            </div>
            <input
              :style="
                purchaseForm.velikostni_podil === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.velikostni_podil"
              @blur="saveData()"
            />
            <!-- Číslo listu vlastnictví -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo listu vlastnictví
              </label>
            </div>
            <input
              :style="
                purchaseForm.list_vlastnictvi === '' && error ? cssInvalid : ''
              "
              v-mask="'#########'"
              :class="inputCSS"
              v-model="purchaseForm.list_vlastnictvi"
              @blur="saveData()"
            />
            <!-- Katastrální pracoviště -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Katastrální pracoviště
              </label>
            </div>
            <input
              :class="inputCSS"
              v-model="purchaseForm.katastralni_pracoviste"
              @blur="saveData()"
            />
            <!-- Cena nemovitosti -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Cena nemovitosti
              </label>
            </div>
            <input
              :style="
                purchaseForm.cena_nemovitosti === '' && error ? cssInvalid : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.cena_nemovitosti"
              @blur="saveData()"
              :placeholder="currentProperty.propertyPrice"
            />
            <!-- Uhrazená část z ceny nemovitosti -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Uhrazená část z ceny nemovitosti (rezervační částka)
              </label>
            </div>
            <input
              :style="
                purchaseForm.cena_nemovitosti_cast_uhrazena === '' && error
                  ? cssInvalid
                  : ''
              "
              :class="inputCSS"
              v-model="purchaseForm.cena_nemovitosti_cast_uhrazena"
              @blur="saveData()"
            />
            <!-- Zbývá uhradit -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Zbývá uhradit
              </label>
            </div>
            <input
              :style="
                purchaseForm.cena_nemovitosti_cast_bude_uhrazena === '' && error
                  ? cssInvalid
                  : ''
              "
              v-mask="'#########'"
              :class="inputCSS"
              v-model="purchaseForm.cena_nemovitosti_cast_bude_uhrazena"
              @blur="saveData()"
            />
            <!-- Výměra balkónu v metrech -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Výměra balkónu v metrech
              </label>
            </div>
            <input
              v-mask="'####'"
              :class="inputCSS"
              v-model="purchaseForm.vymera_balkonu"
              @blur="saveData()"
            />
            <!-- Číslo sklepení -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo sklepení
              </label>
            </div>
            <input
              v-mask="'####'"
              :class="inputCSS"
              v-model="purchaseForm.cislo_sklepeni"
              @blur="saveData()"
            />
            <!-- Číslo podlaží sklepení -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo podlaží sklepení
              </label>
            </div>
            <input
              :class="inputCSS"
              v-model="purchaseForm.podlazi_sklepeni"
              @blur="saveData()"
            />
            <!-- Číslo parkovací -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo parkovací
              </label>
            </div>
            <input
              v-mask="'#######'"
              :class="inputCSS"
              v-model="purchaseForm.cislo_parkovaci"
              @blur="saveData()"
            />
            <!-- Číslo parkovacího podlaží -->
            <div class="pt-4">
              <label class="text-15 text-body-4 font-bold">
                Číslo parkovacího podlaží
              </label>
            </div>
            <input
              :class="inputCSS"
              v-model="purchaseForm.cislo_podlazi_parkovani"
              @blur="saveData()"
            />
          </div>
        </div>
        <div v-if="error" class="text-center p-2 bg-red-200 font-semibold">
          {{ errorMessage }}
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between"
        >
          <button @click="purchasecontract()" type="button" class="btn">
            Poslat na e-mail
          </button>
          <button
            @click="[closePurchaseContract(), (closedQuestion = false)]"
            type="button"
            class="btnC"
          >
            Zavřít okno
          </button>
        </div>
      </div>
    </div>
    <div v-if="successSend">
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-center sm:mt-0 sm:ml-4 sm:text-left pb-2">
                  <h3
                    class="text-xl leading-6 font-bold text-body-1 select-none py-8 text-center"
                    id="modal-title"
                  >
                    <p>Odesláno na {{ this.user.email }}</p>
                  </h3>
                  <div class="flex justify-center pb-4"><ErrorSvg /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getReservationData,
  getCadastralData,
} from "../../services/rest/services";
import axios from "axios";
import { mapState } from "vuex";
import ErrorSvg from "../svg/Error.vue";
import Vue from "vue";
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  props: {
    purchaseContract: {
      type: Boolean,
    },
  },

  components: {
    ErrorSvg,
  },

  data() {
    return {
      cssInvalid:
        "border: 1px solid #fca5a5; background-color: rgba(252,165,165, 0.2);",
      successSend: false,
      error: false,
      errorMessage: "",
      reservationData: [],
      postal: false,
      closedQuestion: false,
      inputCSS:
        "w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",
      purchaseForm: {
        seller_name: "",
        seller_surname: "",
        seller_pin: "",
        seller_permanent_address: "",
        customer_name: "",
        customer_surname: "",
        customer_pin: "",
        customer_psc: "",
        customer_street: "",
        customer_cp: "",
        customer_city: "",
        cislo_jednotky: "",
        vymezeno_v_pozemku: "",
        cislo_popisne: "",
        cislo_parcely: "",
        velikostni_podil: "",
        list_vlastnictvi: "",
        katastralni_pracoviste: "",
        cena_nemovitosti: "",
        cena_nemovitosti_cast_uhrazena: "",
        cena_nemovitosti_cast_bude_uhrazena: "",
        ucet_uschovy: "",
        vymera_balkonu: "",
        cislo_sklepeni: "",
        podlazi_sklepeni: "",
        cislo_parkovaci: "",
        katastralni_uzemi: "",
        cislo_podlazi_parkovani: "",
      },
      start: false,
      podil: "",
      podilNaDomu: "",
    };
  },

  async mounted() {
    setTimeout(() => {
      this.start = true;
    }, 200);
    if (localStorage.getItem("purchaseForm")) {
      this.purchaseForm = JSON.parse(localStorage.getItem("purchaseForm"));
    }
    this.reservationData = await getReservationData(this.currentProperty.id);
    if (!this.reservationData) {
      return;
    }

    await getCadastralData(this.currentProperty.id).then((res) => {
      this.lvData = res.dataInDatabase;
      if (res.data && res.data.data) {
        this.cadastralPlace = res.data.data.cadastralPlace;
        if (this.currentProperty.propertyType === 1) {
          this.podil = res.data.data.podil[0];
          if (this.podil) this.purchaseForm.velikostni_podil = `${this.podil.citatel[0]}/${this.podil.jmenovatel[0]}`;
        }
        if (this.currentProperty.propertyType === 2) {
          if (res.data.data.xmlToJsObject.VLASTNICI_JINI_OPRAVNENI[0]) {
            if (
              res.data.data.xmlToJsObject.VLASTNICI_JINI_OPRAVNENI[0].PODIL[0] !==
              ""
            ) {
              this.podilNaDomu = res.data.data.xmlToJsObject.VLASTNICI_JINI_OPRAVNENI[0].PODIL;
              if (this.podilNaDomu) this.purchaseForm.velikostni_podil = `${this.podilNaDomu[0].citatel[0]}/${this.podilNaDomu[0].jmenovatel[0]}`;
            } else {
              return;
            }
          }
        }
      }
    });

    this.fullFillForm();
  },

  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },

  methods: {
    async purchasecontract() {
      this.error = false;
      if (
        this.purchaseForm.seller_name === "" ||
        this.purchaseForm.seller_surname === "" ||
        this.purchaseForm.seller_pin === "" ||
        this.purchaseForm.seller_permanent_address === "" ||
        this.purchaseForm.customer_name === "" ||
        this.purchaseForm.customer_surname === "" ||
        this.purchaseForm.customer_pin === "" ||
        this.purchaseForm.customer_city === "" ||
        this.purchaseForm.customer_cp === "" ||
        this.purchaseForm.customer_street === "" ||
        this.purchaseForm.customer_psc === "" ||
        this.purchaseForm.katastralni_uzemi === "" ||
        this.purchaseForm.obec === "" ||
        this.purchaseForm.list_vlastnictvi === "" ||
        this.purchaseForm.katastralni_pracoviste === "" ||
        this.purchaseForm.cena_nemovitosti_cast_bude_uhrazena === "" ||
        this.purchaseForm.cislo_uctu === "" ||
        this.purchaseForm.vymezeno_v_pozemku === "" ||
        this.purchaseForm.velikostni_podil === ""
      ) {
        this.error = true;
        return (this.errorMessage = "Údaje nejsou kompletní!");
      }
      // no slash in this.purchaseForm.velikostni_podil!
      // params to request
      if (this.purchaseForm.velikostni_podil === "") {
        this.purchaseForm.velikostni_podil = "/";
      }
      if (this.purchaseForm.velikostni_podil !== "") {
        if (this.purchaseForm.velikostni_podil.includes("/")) {
          this.purchaseForm.velikostni_podil = this.purchaseForm.velikostni_podil.replace(
            "/",
            "."
          );
        }
        if (this.purchaseForm.cislo_jednotky.includes("/")) {
          this.purchaseForm.cislo_jednotky = this.purchaseForm.cislo_jednotky.replace(
            "/",
            "."
          );
        }
      }
      if (this.purchaseForm.velikostni_podil.includes("/")) {
        this.purchaseForm.velikostni_podil = this.purchaseForm.velikostni_podil.replace(
          "/",
          "."
        );
      }
      if (this.purchaseForm.ucet_uschovy.includes("/")) {
        this.purchaseForm.ucet_uschovy = this.purchaseForm.ucet_uschovy.replace(
          "/",
          "."
        );
      }

      const params = JSON.stringify({
        id: this.user.id,
        id_property: this.currentProperty.id,
        name: this.user.nameSurname.split(" ")[0],
        surname: this.user.nameSurname.split(" ")[1],
        email: this.user.username,
        ...this.purchaseForm,
      });

      this.successSend = true;
      const data = await axios.get(
        `${process.env.VUE_APP_STRAPI_API_URL}/properties/purchasecontract/formfill/${params}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }
      );

      if (this.purchaseForm.velikostni_podil.includes("/")) {
        this.purchaseForm.velikostni_podil = this.purchaseForm.velikostni_podil.replace(
          ".",
          "/"
        );
      }
      if (this.purchaseForm.ucet_uschovy.includes("/")) {
        this.purchaseForm.ucet_uschovy = this.purchaseForm.ucet_uschovy.replace(
          ".",
          "/"
        );
      }

      if (data.status === 200) {
        await axios
          .get(
            `${process.env.VUE_APP_STRAPI_API_URL}/properties/purchasecontract/getdocument/${this.currentProperty.id}`,
            {
              headers: {
                Authorization: `Bearer ${this.user.jwt}`,
              },
            }
          )
          .then(
            (this.successSend = true),
            setTimeout(() => {
              this.closePurchaseContract();
            }, 3000)
          );
        return data;
      }
      return data;
    },
    closePurchaseContract() {
      this.$emit("closePurchaseContract", false);
    },
    saveData() {
      window.localStorage.setItem(
        "purchaseForm",
        JSON.stringify(this.purchaseForm)
      );
      this.error = false;
    },
    addressHandler(e) {
      this.postal = e;
    },
    async fullFillForm() {
      this.closedQuestion = true;
      this.purchaseForm.seller_name = this.user.nameSurname.split(" ")[0];
      this.purchaseForm.seller_surname = this.user.nameSurname.split(" ")[1];
      this.purchaseForm.customer_name = this.reservationData.data.customer_name;
      this.purchaseForm.customer_surname = this.reservationData.data.customer_surname;
      this.purchaseForm.customer_pin = this.reservationData.data.customer_pin;
      this.purchaseForm.customer_permanent_address = this.reservationData.data.customer_permanent_address;
      if (
        this.reservationData.data.cena_nemovitosti === null ||
        this.reservationData.data.cena_nemovitosti === ""
      ) {
        return;
      } else {
        this.purchaseForm.cena_nemovitosti = this.currentProperty.propertyPrice.toString();
      }
      this.purchaseForm.vymera_balkonu = this.currentProperty.usableAreaBalcony;
      this.purchaseForm.cislo_popisne = this.currentProperty.address
        .replace(/^\D+/g, "")
        .replace(/\s/g, "")
        .trim();
      // save into localStorage
      if (this.reservationData) {
        this.purchaseForm.cislo_jednotky = this.reservationData.data.cislo_jednotky;
        this.purchaseForm.cislo_parcely = this.reservationData.data.cislo_parcely;
        this.purchaseForm.seller_pin = this.reservationData.data.seller_pin;
        this.purchaseForm.seller_surname = this.reservationData.data.seller_surname;
        this.purchaseForm.seller_surname = this.reservationData.data.seller_surname;
        this.purchaseForm.katastralni_pracoviste = this.reservationData.data.katastralni_pracoviste;
        this.purchaseForm.katastralni_uzemi = this.reservationData.data.katastralni_uzemi;
        this.purchaseForm.seller_permanent_address = this.reservationData.data.seller_permanent_address;
        this.purchaseForm.list_vlastnictvi = this.reservationData.data.list_vlastnictvi;
        const uhrazeno = parseInt(
          this.reservationData.data.cena_nemovitosti_cast_bude_uhrazena
        );
        const cenaMajetku = parseInt(this.purchaseForm.cena_nemovitosti);
        const zbyvaUhradit = cenaMajetku - uhrazeno;
        this.purchaseForm.cena_nemovitosti_cast_bude_uhrazena = zbyvaUhradit.toString();
        this.purchaseForm.cena_nemovitosti_cast_uhrazena = uhrazeno.toString();
        this.purchaseForm.customer_city = this.reservationData.data.customer_city;
        this.purchaseForm.customer_cp = this.reservationData.data.customer_cp;
        this.purchaseForm.customer_street = this.reservationData.data.customer_street;
        this.purchaseForm.customer_psc = this.reservationData.data.customer_psc;

        if (this.currentProperty) {
          this.purchaseForm.vymezeno_v_pozemku = this.currentProperty.usableAreaApartment;
        }
      }
      this.saveData();
    },
    onClickOutside() {
      if (this.start) {
        this.closePurchaseContract();
      }
    },
  },
};
</script>
<style scoped>
.btn {
  background-color: #1b26e2 !important;
  color: white !important;
  border-radius: 8px !important;
  margin: 8px;
  height: 50px !important;
  width: 180px !important;
}
.btnC {
  background-color: #ffffff !important;
  color: #1b26e2 !important;
  border-radius: 8px !important;
  border: 2px solid #1b26e2;
  margin: 8px;
  height: 50px !important;
  width: 120px !important;
}
.btn:hover,
.btnC:hover {
  opacity: 0.7;
}
</style>
