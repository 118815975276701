<template>
  <div
    v-if="cadastralModalOpen"
    class="fixed z-40 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="z-50 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        v-click-outside="onClickOutside"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <!-- Podílení účastníků -->
          <div class="mt-3 text-left sm:mt-0 sm:text-left p-0">
            <div class="bg-blue-200 p-2 font-bold rounded-md">
              Údaje o účastnících řízení – fyzických osobách
            </div>
            <div class="grid grid-cols-2">
              <button
                @click="participantHandler()"
                class="border-2 rounded-md p-2 mr-4 mt-4"
                :class="[ participant >= 4 ? 'border-grey-700 cursor-default' : 'border-blue-700 hover:bg-blue-200' ]"
                :disabled='participant >= 4'
              >
                Přidat účastníka řízení
              </button>
              <button
                @click="participantDeleteHandler()"
                class="p-2 ml-4 mt-4 border-2 rounded-md"
                :class="[ participant < 2 ? 'border-grey-700 cursor-default' : 'border-red-700 hover:bg-red-200' ]"
                :disabled='participant < 2'
              >
                Odebrat účastníka
              </button>
            </div>
            <!-- Účastník jedna -->
            <div v-if="participant >= 1">
              <p class="pt-6 text-body-1">Účastník jedna (Pořadové číslo 1)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Příjmení -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Příjmení*
                  </label>
                  <input
                    :class="inputCSS"
                    :style="
                      cadastralForm.participant1_prijmeni === '' && error
                        ? cssInvalid
                        : ''
                    "
                    v-model="cadastralForm.participant1_prijmeni"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Jméno -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Jméno* </label>
                  <input
                    :class="inputCSS"
                    :style="
                      cadastralForm.participant1_jmeno === '' && error
                        ? cssInvalid
                        : ''
                    "
                    v-model="cadastralForm.participant1_jmeno"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Rodné číslo -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Rodné číslo bez lomítka*
                  </label>
                  <input
                    :class="inputCSS"
                    :style="
                      cadastralForm.participant1_rc === '' && error
                        ? cssInvalid
                        : ''
                    "
                    v-model="cadastralForm.participant1_rc"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Ulice -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Ulice* </label>
                  <input
                    :class="inputCSS"
                    :style="
                      cadastralForm.participant1_ulice_trvala === '' && error
                        ? cssInvalid
                        : ''
                    "
                    v-model="cadastralForm.participant1_ulice_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo popisné -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo popisné*
                  </label>
                  <input
                    :style="
                      cadastralForm.participant1_cp_trvala === '' &&
                      cadastralForm.participant1_co_trvala === '' &&
                      error
                        ? cssInvalid
                        : ''
                    "
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_cp_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo orientační -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo orientační
                  </label>
                  <input
                    :style="
                      cadastralForm.participant1_cp_trvala === '' &&
                      cadastralForm.participant1_co_trvala === '' &&
                      error
                        ? cssInvalid
                        : ''
                    "
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_co_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Část obce -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Část obce
                  </label>
                  <input
                    :style="
                      cadastralForm.participant1_cast_obce_trvala === '' &&
                      error
                        ? cssInvalid
                        : ''
                    "
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_cast_obce_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- PSČ -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> PSČ* </label>
                  <input
                    :style="
                      cadastralForm.participant1_psc_trvala === '' && error
                        ? cssInvalid
                        : ''
                    "
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_psc_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Obec -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Obec* </label>
                  <input
                    :class="inputCSS"
                    :style="
                      cadastralForm.participant1_obec_trvala === '' && error
                        ? cssInvalid
                        : ''
                    "
                    v-model="cadastralForm.participant1_obec_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>

                <!-- PŘECHODNÉ BYDLIŠTĚ -->

                <!-- Ulice
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Ulice (přechodné bydliště)
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_ulice_prechodna"
                    @blur="saveData()"
                  />
                </div> -->

                <!-- Číslo popisné -->

                <!-- <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo popisné (přechodné bydliště)
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_cp_prechodna"
                    @blur="saveData()"
                  />
                </div> -->

                <!-- Číslo orientační -->

                <!-- <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo orient. (přechodné bydliště)
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_co_prechodna"
                    @blur="saveData()"
                  />
                </div> -->

                <!-- Část obce -->

                <!-- <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Část obce (přechodné bydliště)
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_cast_obce_prechodna"
                    @blur="saveData()"
                  />
                </div> -->

                <!-- PSČ -->

                <!-- <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    PSČ (přechodné bydliště)
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_psc_prechodna"
                    @blur="saveData()"
                  />
                </div> -->

                <!-- Obec -->

                <!-- <div>
                  <label class="text-sm text-body-4 font-bold">
                    Obec (přechodné bydliště)
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_obec_prechodna"
                    @blur="saveData()"
                  />
                </div> -->

                <!-- ^^^ PŘECHODNÉ BYDLIŠTĚ ^^^ -->
              </div>
            </div>
            <!-- Účastník dva -->
            <div v-if="participant >= 2">
              <p class="pt-6 text-body-1">Účastník dva (Pořadové číslo 2)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Příjmení -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Příjmení
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_prijmeni"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Jméno -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Jméno </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_jmeno"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Rodné číslo -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Rodné číslo bez lomítka
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_rc"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Ulice -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Ulice </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_ulice_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo popisné -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo popisné
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_cp_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo orientační -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo orient.
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_co_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Část obce -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Část obce
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_cast_obce_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- PSČ -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> PSČ </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_psc_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Obec -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Obec </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_obec_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
              </div>
            </div>
            <!-- Účastník tři -->
            <div v-if="participant >= 3">
              <p class="pt-6 text-body-1">Účastník tři (Pořadové číslo 3)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Příjmení -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Příjmení
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_prijmeni"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Jméno -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Jméno </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_jmeno"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Rodné číslo -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Rodné číslo bez lomítka
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_rc"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Ulice -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Ulice </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_ulice_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo popisné -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo popisné
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_cp_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo orientační -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo orient.
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_co_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Část obce -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Část obce
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_cast_obce_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- PSČ -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> PSČ </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_psc_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Obec -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Obec </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_obec_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
              </div>
            </div>
            <!-- Účastník čtyři -->
            <div v-if="participant >= 4">
              <p class="pt-6 text-body-1">Účastník čtyři (Pořadové číslo 4)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Příjmení -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Příjmení
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_prijmeni"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Jméno -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Jméno </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_jmeno"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Rodné číslo -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Rodné číslo bez lomítka
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_rc"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Ulice -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Ulice </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_ulice_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo popisné -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo popisné
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_cp_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Číslo orientační -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Číslo orient.
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_co_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Část obce -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Část obce
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_cast_obce_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- PSČ -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> PSČ </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_psc_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
                <!-- Obec -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> Obec </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_obec_trvala"
                    @blur="saveData()"
                    @change="saveData()"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- ^ Podílení účastníků ^ -->
          <!-- Označení práv pro pozemky -->
          <div class="mt-3 text-left sm:mt-0 sm:text-left p-0">
            <div class="bg-blue-200 p-2 font-bold rounded-md mt-4">
              Označení práv, která mají být zapsána ke stavbám, pozemkům a právu
              stavby
            </div>
            <div class="text-justify pt-2">
              Uveďte stručný popis práva podle předložené listiny. V případě
              vlastnického práva uveďte zejména jeho nabyvatele, dále zda je
              právo nabýváno k celku nebo jsou nemovitosti nabývány do
              spoluvlastnictví, zda jsou nemovitosti nabývány do společného
              jmění manželů nebo do jiného majetkového společenství manželů
              podle cizího práva atd. Nabývá-li nemovitost do svého
              spoluvlastnictví více osob, uveďte, zda jsou jejich podíly stejné,
              pokud stejné nejsou, uveďte i velikost těchto podílů pro
              jednotlivé osoby.
            </div>
            <div class="mt-4 border-2 p-4 rounded-md border-blue-500">
              <!-- Nabyvatel -->
              <!-- K pozemku -->
              <div class="pb-2">
                <label class="text-sm text-body-4 font-bold">
                  K následujícímu pozemku:
                </label>
                <div class="grid grid-cols-2">
                  <button
                    @click="landHandler()"
                    class="border-2 rounded-md p-2 mr-4 mt-4"
                    :class="[ land >= 4 ? 'border-grey-700 cursor-default' : 'border-blue-700 hover:bg-blue-200' ]"
                    :disabled='land >= 4'
                  >
                    Přidat pozemek
                  </button>
                  <button
                    @click="landDeleteHandler()"
                    class="p-2 ml-4 mt-4 border-2 rounded-md"
                    :class="[ land < 2 ? 'border-grey-700 cursor-default' : 'border-red-700 hover:bg-red-200' ]"
                    :disabled='land < 2'
                  >
                    Odebrat pozemek
                  </button>
                </div>
                <div class="mt-4 p-2">
                  <div
                    v-if="land >= 1"
                    class="pb-6 border-2 p-4 border-blue-400 rounded-md"
                  >
                    <p class="text-body-1 pb-2">Pozemek 1</p>
                    <label class="text-sm text-body-4 font-bold">
                      Katastrální území*
                    </label>
                    <input
                      :class="inputCSS"
                      :style="
                        cadastralForm.land1_ku === '' && error ? cssInvalid : ''
                      "
                      v-model="cadastralForm.land1_ku"
                      @blur="saveData()"
                    />
                    <label class="text-sm text-body-4 font-bold">
                      Parcelní číslo*
                    </label>
                    <input
                      :class="inputCSS"
                      :style="
                        cadastralForm.land1_pc === '' && error ? cssInvalid : ''
                      "
                      v-model="cadastralForm.land1_pc"
                      @blur="saveData()"
                    />
                  </div>
                  <div
                    v-if="land >= 2"
                    class="pb-6 mt-6 border-2 p-4 border-blue-400 rounded-md"
                  >
                    <p class="text-body-1 pb-2">Pozemek 2</p>
                    <label class="text-sm text-body-4 font-bold">
                      Katastrální území
                    </label>
                    <input
                      :class="inputCSS"
                      v-model="cadastralForm.land2_ku"
                      @blur="saveData()"
                    />
                    <label class="text-sm text-body-4 font-bold">
                      Parcelní číslo
                    </label>
                    <input
                      :class="inputCSS"
                      v-model="cadastralForm.land2_pc"
                      @blur="saveData()"
                    />
                  </div>
                  <div
                    v-if="land >= 3"
                    class="pb-6 mt-6 border-2 p-4 border-blue-400 rounded-md"
                  >
                    <p class="text-body-1 pb-2">Pozemek 3</p>
                    <label class="text-sm text-body-4 font-bold">
                      Katastrální území
                    </label>
                    <input
                      :class="inputCSS"
                      v-model="cadastralForm.land3_ku"
                      @blur="saveData()"
                    />
                    <label class="text-sm text-body-4 font-bold">
                      Parcelní číslo
                    </label>
                    <input
                      :class="inputCSS"
                      v-model="cadastralForm.land3_pc"
                      @blur="saveData()"
                    />
                  </div>
                  <div
                    v-if="land >= 4"
                    class="pb-6 mt-6 border-2 p-4 border-blue-400 rounded-md"
                  >
                    <p class="text-body-1 pb-2">Pozemek 4</p>
                    <label class="text-sm text-body-4 font-bold">
                      Katastrální území
                    </label>
                    <input
                      :class="inputCSS"
                      v-model="cadastralForm.land4_ku"
                      @blur="saveData()"
                    />
                    <label class="text-sm text-body-4 font-bold">
                      Parcelní číslo
                    </label>
                    <input
                      :class="inputCSS"
                      v-model="cadastralForm.land4_pc"
                      @blur="saveData()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ^ Označení práv pro pozemky ^ -->

          <!-- Sdělení pro katastrální úřad -->
          <div class="mt-3 text-left sm:mt-0 sm:text-left p-0">
            <div class="bg-blue-200 p-2 font-bold rounded-md mt-4">
              Sdělení pro katastrální úřad
            </div>
            <div class="text-justify pt-2">
              Zde můžete uvést údaje, které v případě potřeby urychlí Vaše
              kontaktování a řešení případného problému. Uvedení kontaktních
              údajů je nepovinné.
            </div>
            <!-- Účastník jedna -->
            <div v-if="participant >= 1">
              <p class="pt-6 text-body-1">Účastník jedna (Pořadové číslo 1)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Email -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> E-mail </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_email"
                    @blur="saveData()"
                  />
                </div>
                <!-- Telefon -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Telefonní číslo
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant1_telefon"
                    @blur="saveData()"
                  />
                </div>
              </div>
            </div>
            <!-- Účastník dva -->
            <div v-if="participant >= 2">
              <p class="pt-6 text-body-1">Účastník dva (Pořadové číslo 2)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Email -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> E-mail </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_email"
                    @blur="saveData()"
                  />
                </div>
                <!-- Telefon -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Telefonní číslo
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant2_telefon"
                    @blur="saveData()"
                  />
                </div>
              </div>
            </div>
            <!-- Účastník tři -->
            <div v-if="participant >= 3">
              <p class="pt-6 text-body-1">Účastník tři (Pořadové číslo 3)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Email -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> E-mail </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_email"
                    @blur="saveData()"
                  />
                </div>
                <!-- Telefon -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Telefonní číslo
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant3_telefon"
                    @blur="saveData()"
                  />
                </div>
              </div>
            </div>
            <!-- Účastník čtyři -->
            <div v-if="participant >= 4">
              <p class="pt-6 text-body-1">Účastník čtyři (Pořadové číslo 4)</p>
              <div
                class="grid grid-cols-2 gap-2 mt-4 border-2 p-2 rounded-md border-blue-500"
              >
                <!-- Email -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold"> E-mail </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_email"
                    @blur="saveData()"
                  />
                </div>
                <!-- Telefon -->
                <div class="pb-2">
                  <label class="text-sm text-body-4 font-bold">
                    Telefonní číslo
                  </label>
                  <input
                    :class="inputCSS"
                    v-model="cadastralForm.participant4_telefon"
                    @blur="saveData()"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- ^ Sdělení pro katastrální úřad ^ -->
        </div>
        <div
          v-if="error"
          class="text-center py-2 px-4 bg-red-200 font-semibold"
        >
          {{ eMsg }}
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between"
        >
          <button @click="cadastralDeposit()" type="button" class="btn">
            Poslat na e-mail
          </button>
          <button @click="close()" type="button" class="btnC">
            Zavřít okno
          </button>
        </div>
      </div>
    </div>
    <div v-if="successSend">
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-center sm:text-left pb-2">
                  <h3
                    class="text-xl leading-6 font-bold text-body-1 select-none py-8 text-center"
                    id="modal-title"
                  >
                    <p>Odesláno na {{ this.user.email }}</p>
                  </h3>
                  <div class="flex justify-center pb-4">
                    <ErrorSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCadastralData,
  getPurchaseData,
  getReservationData,
} from "../../services/rest/services.js";
import axios from "axios";
import { mapState } from "vuex";
import ErrorSvg from "../svg/Error.vue";
import Vue from "vue";
Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  props: {
    cadastralModalOpen: {
      type: Boolean,
    },
  },

  components: {
    ErrorSvg,
  },

  data() {
    return {
      successSend: false,
      eMsg: "",
      cssInvalid:
        "border: 1px solid #fca5a5; background-color: rgba(252,165,165, 0.2);",
      error: false,
      start: 0,
      land: 1,
      participant: 1,
      inputCSS:
        "w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",
      cadastralForm: {
        participant1_poradove_cislo: "1",
        participant1_prijmeni: "",
        participant1_jmeno: "",
        participant1_rc: "",
        participant1_ulice_trvala: "",
        participant1_cp_trvala: "",
        participant1_co_trvala: "",
        participant1_cast_obce_trvala: "",
        participant1_psc_trvala: "",
        participant1_obec_trvala: "",
        participant2_poradove_cislo: "2",
        participant2_prijmeni: "",
        participant2_jmeno: "",
        participant2_rc: "",
        participant2_ulice_trvala: "",
        participant2_cp_trvala: "",
        participant2_co_trvala: "",
        participant2_cast_obce_trvala: "",
        participant2_psc_trvala: "",
        participant2_obec_trvala: "",
        participant3_poradove_cislo: "3",
        participant3_prijmeni: "",
        participant3_jmeno: "",
        participant3_rc: "",
        participant3_ulice_trvala: "",
        participant3_cp_trvala: "",
        participant3_co_trvala: "",
        participant3_cast_obce_trvala: "",
        participant3_psc_trvala: "",
        participant3_obec_trvala: "",

        nabyvatel: "",
        cislo_jednotky: "",

        land1_ku: "",
        land1_pc: "",
        land2_ku: "",
        land2_pc: "",
        participant1_email: "",
        participant2_email: "",
        participant1_telefon: "",
        participant2_telefon: "",
      },
      purchaseData: [],
      reservationData: [],
      documentCase: null,
      reservationAble: false,
      purchaseAble: false,
    };
  },

  async mounted() {
    const data = await getCadastralData(this.currentProperty.id);
    this.purchaseData = await getPurchaseData(this.currentProperty.id);
    this.reservationData = await getReservationData(this.currentProperty.id);
    if (data.dataInDatabase) {
      const lvData = data.data.data;
      this.documentCase = data.data.data.documentCase;
      if (lvData) {
        this.caseHandler(lvData);
      }
    }
    if (localStorage.getItem("cadastralForm")) {
      this.cadastralForm = JSON.parse(localStorage.getItem("cadastralForm"));
    }
    // Try to catch purchaser from reservation form \\
    if (this.reservationData) {
      this.reservationData = this.reservationData.data;
      this.reservationAble = true;
    }
    // Try to catch purchaser from purchase form \\
    if (this.purchaseData.status == 200) {
      if (this.purchaseData.data !== null) {
        this.purchaseData = this.purchaseData.data;
        this.purchaseAble = true;
      }
    }
    this.fillPurchaserAndAdditionals();
  },

  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },

  methods: {
    fillPurchaser(data) {
      if (this.documentCase == 2) {
        this.participant = this.participant + 1;
        this.cadastralForm.participant3_poradove_cislo = "3";
        this.cadastralForm.participant3_prijmeni = data.customer_surname;
        this.cadastralForm.participant3_jmeno = data.customer_name;
        this.cadastralForm.participant3_rc = data.customer_pin;
        this.cadastralForm.participant3_ulice_trvala =
          data.customer_permanent_address[0];
        this.cadastralForm.participant3_ulice_trvala = data.customer_street;
        this.cadastralForm.participant3_cp_trvala = data.customer_cp;
        this.cadastralForm.participant3_cast_obce_trvala = data.customer_city;
        this.cadastralForm.participant3_psc_trvala = data.customer_psc;
        this.cadastralForm.participant3_obec_trvala = data.customer_city;
      }
      if (this.documentCase != 2) {
        this.participant = this.participant + 1;
        this.cadastralForm.participant2_poradove_cislo = "2";
        this.cadastralForm.participant2_prijmeni = data.customer_surname;
        this.cadastralForm.participant2_jmeno = data.customer_name;
        this.cadastralForm.participant2_rc = data.customer_pin;
        this.cadastralForm.participant2_ulice_trvala =
          data.customer_permanent_address[0];
        this.cadastralForm.participant2_ulice_trvala = data.customer_street;
        this.cadastralForm.participant2_cp_trvala = data.customer_cp;
        this.cadastralForm.participant2_cast_obce_trvala = data.customer_city;
        this.cadastralForm.participant2_psc_trvala = data.customer_psc;
        this.cadastralForm.participant2_obec_trvala = data.customer_city;
      }
    },
    flatNumHandler(p, c) {
      if (p) {
        if (p.cislo_jednotky !== "") {
          this.cadastralForm.cislo_jednotky = p.cislo_jednotky;
        }
        return;
      }
      if (c) {
        if (c.cislo_jednotky !== "") {
          this.cadastralForm.cislo_jednotky = c.cislo_jednotky;
        }
        return;
      }
    },
    async fillPurchaserAndAdditionals() {
      if (this.purchaseData.Data) {
        this.cadastralForm.participant1_jmeno = this.user.nameSurname.split(
          " "
        )[0];
        this.cadastralForm.participant1_prijmeni = this.user.nameSurname.split(
          " "
        )[1];
        this.cadastralForm.participant1_rc = "";
        if (this.purchaseAble || this.reservationAble) {
          this.flatNumHandler(this.purchaseData.Data, this.reservationData);
          this.fillPurchaser(this.purchaseData.Data);
          if (this.purchaseAble && !this.reservationAble) {
            this.fillPurchaser(this.purchaseData.Data);
          }
          if (!this.purchaseAble && this.reservationAble) {
            this.fillPurchaser(this.reservationData);
          }
        } else return;
      } else {
        this.cadastralForm.participant1_jmeno = this.user.nameSurname.split(
          " "
        )[0];
        this.cadastralForm.participant1_prijmeni = this.user.nameSurname.split(
          " "
        )[1];
        this.cadastralForm.participant1_rc = "";
      }
    },
    async cadastralDeposit() {
      const valid = this.validator();
      //invalid inputs
      if (!valid) {
        return;
      }
      //valid inputs
      const params = JSON.stringify({
        id: this.user.id,
        id_property: this.currentProperty.id,
        name: this.user.nameSurname.split(" ")[0],
        surname: this.user.nameSurname.split(" ")[1],
        email: this.user.username,
        ...this.cadastralForm,
        case: this.documentCase || 3,
      });
      const { data } = await axios.get(
        `${process.env.VUE_APP_STRAPI_API_URL}/properties/cadastraldeposit/${params}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        }
      );
      if (data === 200) {
        this.successSend = true;
        setTimeout(() => {
          this.successSend = false;
          this.close();
        }, 3000);
      } else {
        this.eMsg = "Chyba komunikace se serverem!";
        this.error = true;
        setTimeout(() => {
          this.close();
        }, 3000);
      }
      return data;
    },
    close() {
      this.$emit("close", false);
    },
    saveData() {
      this.error = false;
      window.localStorage.setItem(
        "cadastralForm",
        JSON.stringify(this.cadastralForm)
      );
    },
    participantHandler() {
      this.participant = this.participant + 1;
      if (this.participant === 2) {
        return (this.participant2_poradove_cislo = "2");
      }
      if (this.participant === 3) {
        return (this.participant2_poradove_cislo = "3");
      }
      if (this.participant === 4) {
        return (this.participant2_poradove_cislo = "4");
      }
    },
    participantDeleteHandler() {
      this.participant = this.participant - 1;
    },
    landHandler() {
      this.land = this.land + 1;
    },
    landDeleteHandler() {
      this.land = this.land - 1;
    },
    onClickOutside() {
      if (this.start == 0 && this.cadastralModalOpen) {
        return (this.start = 1);
      }
      if (this.start == 1) {
        this.start = 0;
        return this.close();
      }
    },
    caseHandler(i) {
      const { lvData } = i;
      if (i.documentCase == 1) {
        const { xmlToJsObject } = i;
        this.fillLvIdentity(lvData);
        this.fillCaseOne(xmlToJsObject);
        this.landHandlerCase(
          xmlToJsObject.POZEMKY,
          lvData.KATASTR_UZEMI[0].nazev[0]
        );
        return i;
      }
      if (i.documentCase == 2) {
        const { xmlToJsObject } = i;
        this.fillLvIdentity(lvData);
        this.fillCaseTwo(xmlToJsObject);
        this.landHandlerCase(
          xmlToJsObject.POZEMKY,
          lvData.KATASTR_UZEMI[0].nazev[0]
        );
        this.participant = this.participant + 1;
        return i;
      }
      if (i.documentCase == 3) {
        this.fillLvIdentity(lvData);
        return i;
      } else return this.dataFromUser();
    },
    fillLvIdentity(i) {
      this.cadastralForm.land1_ku = i.KATASTR_UZEMI_NAZEV;
    },
    dataFromUser() {
      const { user_id } = this.currentProperty;
      this.cadastralForm.participant1_prijmeni = user_id.nameSurname.split(
        " "
      )[1];
      this.cadastralForm.participant1_jmeno = user_id.nameSurname.split(" ")[0];
    },
    fillCaseOneForEachCase(i) {
      const sub1rc = `${i.VLASTNICI_JINI_OPRAVNENI[0].OPSUB_IDENT[0].rc6}${i.VLASTNICI_JINI_OPRAVNENI[0].OPSUB_IDENT[0].rc7}`;
      const sub1name = `${i.VLASTNICI_JINI_OPRAVNENI[0].OPSUB_NAZEV[0].jmeno}`;
      const sub1surname = `${i.VLASTNICI_JINI_OPRAVNENI[0].OPSUB_NAZEV[0].prijmeni}`;
      const sub1address =
        i.VLASTNICI_JINI_OPRAVNENI[0].VLA_IDENT[0].oprav_subjekt[0].adresa[0];

      this.cadastralForm.participant1_rc = sub1rc;
      this.cadastralForm.participant1_jmeno = sub1name;
      this.cadastralForm.participant1_prijmeni = sub1surname;
      this.cadastralForm.participant1_ulice_trvala = sub1address.nazev_ulice[0];

      if (sub1address.nazev_ulice[0] === "") {
        this.cadastralForm.participant1_ulice_trvala = sub1address.cast_obce[0];
        this.cadastralForm.participant1_cast_obce_trvala = this.cadastralForm.participant1_ulice_trvala;
      } else {
        this.cadastralForm.participant1_cast_obce_trvala =
          sub1address.cast_obce[0];
      }
      this.cadastralForm.participant1_cp_trvala = sub1address.cislo_domovni[0];
      this.cadastralForm.participant1_co_trvala =
        sub1address.cislo_orientacni[0];
      this.cadastralForm.participant1_obec_trvala = sub1address.obec[0];
      this.cadastralForm.participant1_psc_trvala = sub1address.psc[0];
      // from user data
      this.cadastralForm.participant1_email = this.user.email;
      this.cadastralForm.participant1_telefon = this.user.phone;
    },
    fillCaseOne(i) {
      this.fillCaseOneForEachCase(i);
    },
    fillCaseTwo(i) {
      this.fillCaseOneForEachCase(i);
      const sub2rc = `${i.VLASTNICI_JINI_OPRAVNENI[1].OPSUB_IDENT[0].rc6}${i.VLASTNICI_JINI_OPRAVNENI[1].OPSUB_IDENT[0].rc7}`;
      const sub2name = `${i.VLASTNICI_JINI_OPRAVNENI[1].OPSUB_NAZEV[0].jmeno}`;
      const sub2surname = `${i.VLASTNICI_JINI_OPRAVNENI[1].OPSUB_NAZEV[0].prijmeni}`;
      const sub2address =
        i.VLASTNICI_JINI_OPRAVNENI[1].VLA_IDENT[0].oprav_subjekt[0].adresa[0];
      this.cadastralForm.participant2_rc = sub2rc;
      this.cadastralForm.participant2_jmeno = sub2name;
      this.cadastralForm.participant2_prijmeni = sub2surname;
      this.cadastralForm.participant2_ulice_trvala = sub2address.nazev_ulice[0];
      this.cadastralForm.participant2_cp_trvala = sub2address.cislo_domovni[0];
      this.cadastralForm.participant2_co_trvala =
        sub2address.cislo_orientacni[0];
      this.cadastralForm.participant2_cast_obce_trvala =
        sub2address.cast_obce[0];
      this.cadastralForm.participant2_obec_trvala = sub2address.obec[0];
      this.cadastralForm.participant2_psc_trvala = sub2address.psc[0];
    },
    landHandlerCase(lands, village) {
      if (lands) {
        if (lands.length == 1) {
          this.land = 1;
          this.cadastralForm.land1_pc = lands[0].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land1_ku = village;
        }
        if (lands.length == 2) {
          this.land = this.land + lands.length - 1;
          this.cadastralForm.land1_pc = lands[0].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land2_pc = lands[1].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land1_ku = village;
          this.cadastralForm.land2_ku = village;
        }
        if (lands.length == 3) {
          this.land = this.land + lands.length;
          this.cadastralForm.land1_pc = lands[0].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land2_pc = lands[1].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land3_pc = lands[2].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land1_ku = village;
          this.cadastralForm.land2_ku = village;
          this.cadastralForm.land3_ku = village;
        }
        if (lands.length == 4) {
          this.land = this.land + lands.length + 1;
          this.cadastralForm.land1_pc = lands[0].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land2_pc = lands[1].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land3_pc = lands[2].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land4_pc = lands[3].PAR_IDENT[0].parcela[0].par_cis[0].trim();
          this.cadastralForm.land1_ku = village;
          this.cadastralForm.land2_ku = village;
          this.cadastralForm.land3_ku = village;
          this.cadastralForm.land4_ku = village;
        }
      }
      if (!lands) {
        if (this.purchaseData) {
          if (this.purchaseData.data) {
            if (this.purchaseData.data.Data) {
              this.cadastralForm.land1_pc = this.purchaseData.data.Data.cislo_parcely;
              this.cadastralForm.land1_ku = this.purchaseData.data.Data.katastralni_uzemi;
            }
          }
        }
      }
    },
    validator() {
      const f = this.cadastralForm;
      if (
        !f.land1_ku ||
        !f.land1_pc ||
        !f.participant1_prijmeni ||
        !f.participant1_jmeno ||
        !f.participant1_rc ||
        !f.participant1_ulice_trvala ||
        !f.participant1_psc_trvala ||
        !f.participant1_obec_trvala
      ) {
        this.error = true;
        this.eMsg = "Údaje nejsou kompletní!";
        return false;
      }
      if (!f.participant1_cp_trvala) {
        this.error = true;
        this.eMsg = "Vyplňte číslo popisné";
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.btn {
  background-color: #1b26e2 !important;
  color: white !important;
  border-radius: 8px !important;
  margin: 8px;
  height: 50px !important;
  width: 180px !important;
}

.btnC {
  background-color: #ffffff !important;
  color: #1b26e2 !important;
  border-radius: 8px !important;
  border: 2px solid #1b26e2;
  margin: 8px;
  height: 50px !important;
  width: 120px !important;
}

.btn:hover,
.btnC:hover {
  opacity: 0.7;
}
</style>
